import React from 'react';

import SEO from '../../components/layout/SEO';

const CollaborationAgreement = () => (
    <SEO
        allowIndexing={false}
        redirect="https://roundups-marketing-site.s3.eu-west-1.amazonaws.com/PDFs/Ripples+Collaboration+Agreement+3.0.pdf"
    />
);

export default CollaborationAgreement;
